import React, { useContext, useEffect } from "react";
import { StoreContext } from "../../store/StoreProvider";
import CardComponent from "../CardComponent";
import { useBreakpoint } from "../../context/breakpointContext";

const ProductList = ({
  productSelected,
  setModalCoverages,
  multitripLayout,
}) => {
  const [store, dispatch] = useContext(StoreContext);
  let dataLayer = window.dataLayer || [];
  const { currentBreakpoint } = useBreakpoint();

  const scrollToCover = () => {};

  return (
    <>
      {store?.products?.list.length > 0 && (
        <div
          className={
            "w-full flex items-center justify-center products-cards-wrapper flex-wrap " +
            (currentBreakpoint === "xs" || currentBreakpoint === "sm" ? "flex-col" : "flex-row")
          }
        >
          {store?.products?.list.map((product, index) => (
            <CardComponent
              index={index}
              setModalCoverages={setModalCoverages}
              selectProduct={productSelected}
              gotoCoverages={scrollToCover}
              key={index}
              product={product}
              multitripLayout={multitripLayout}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ProductList;
