import { React } from "react";

const IcoUser = () => {
  return (
    <div className="w-[17px] h-[17px] flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
      >
        <path
          d="M8.03665 7.69639C9.36818 7.69639 10.4476 6.61697 10.4476 5.28545C10.4476 3.95393 9.36818 2.87451 8.03665 2.87451C6.70513 2.87451 5.62572 3.95393 5.62572 5.28545C5.62572 6.61697 6.70513 7.69639 8.03665 7.69639Z"
          fill="#675CE5"
        />
        <path
          d="M2.41113 14.9292C2.41113 11.8223 4.92976 9.30368 8.03665 9.30368C11.1435 9.30368 13.6622 11.8223 13.6622 14.9292H2.41113Z"
          fill="#675CE5"
        />
      </svg>
    </div>
  );
};

export default IcoUser;
