import { React } from "react";

const IcoShield = () => {
  return (
    <div className="w-[20px] flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10.5 12.9999V14.4999C10.5 14.7832 10.5958 15.0207 10.7875 15.2124C10.9792 15.4041 11.2167 15.4999 11.5 15.4999H12.5C12.7833 15.4999 13.0208 15.4041 13.2125 15.2124C13.4042 15.0207 13.5 14.7832 13.5 14.4999V12.9999H15C15.2833 12.9999 15.5208 12.9041 15.7125 12.7124C15.9042 12.5207 16 12.2832 16 11.9999V10.9999C16 10.7166 15.9042 10.4791 15.7125 10.2874C15.5208 10.0957 15.2833 9.9999 15 9.9999H13.5V8.4999C13.5 8.21657 13.4042 7.97907 13.2125 7.7874C13.0208 7.59574 12.7833 7.4999 12.5 7.4999H11.5C11.2167 7.4999 10.9792 7.59574 10.7875 7.7874C10.5958 7.97907 10.5 8.21657 10.5 8.4999V9.9999H9C8.71667 9.9999 8.47917 10.0957 8.2875 10.2874C8.09583 10.4791 8 10.7166 8 10.9999V11.9999C8 12.2832 8.09583 12.5207 8.2875 12.7124C8.47917 12.9041 8.71667 12.9999 9 12.9999H10.5ZM12 21.8999C11.8833 21.8999 11.775 21.8916 11.675 21.8749C11.575 21.8582 11.475 21.8332 11.375 21.7999C9.125 21.0499 7.33333 19.6624 6 17.6374C4.66667 15.6124 4 13.4332 4 11.0999V6.3749C4 5.95824 4.12083 5.58324 4.3625 5.2499C4.60417 4.91657 4.91667 4.6749 5.3 4.5249L11.3 2.2749C11.5333 2.19157 11.7667 2.1499 12 2.1499C12.2333 2.1499 12.4667 2.19157 12.7 2.2749L18.7 4.5249C19.0833 4.6749 19.3958 4.91657 19.6375 5.2499C19.8792 5.58324 20 5.95824 20 6.3749V11.0999C20 13.4332 19.3333 15.6124 18 17.6374C16.6667 19.6624 14.875 21.0499 12.625 21.7999C12.525 21.8332 12.425 21.8582 12.325 21.8749C12.225 21.8916 12.1167 21.8999 12 21.8999ZM12 19.8999C13.7333 19.3499 15.1667 18.2499 16.3 16.5999C17.4333 14.9499 18 13.1166 18 11.0999V6.3749L12 4.1249L6 6.3749V11.0999C6 13.1166 6.56667 14.9499 7.7 16.5999C8.83333 18.2499 10.2667 19.3499 12 19.8999Z"
          fill="#00C7A6"
        />
      </svg>
    </div>
  );
};

export default IcoShield;
