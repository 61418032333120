const initialStore = {
  products: {
    list: [],
    more: [],
  },
};

const types = {
  setProducts: "products/set",
  setMore: "more/set"
};

const storeReducer = (state, action) => {
  // console.error("StoreReducer action --->", action);
  switch (action.type) {
    case types.setProducts:
      return {
        ...state,
        products: { ...state.products, list: action.payload },
      };
    case types.setMore:
      return {
        ...state,
        products: { ...state.products, more: action.payload },
      };
    default:
      return state;
  }
};

export { initialStore, types };
export default storeReducer;
