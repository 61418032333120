import { React } from "react";

const IcoCalendar = () => {
  return (
    <div className="w-[17px] h-[17px] flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.00992 1.81982C4.54876 1.81982 4.17492 2.19367 4.17492 2.65482V3.48982H3.33992C2.41761 3.48982 1.66992 4.23751 1.66992 5.15982V13.5098C1.66992 14.4321 2.41761 15.1798 3.33992 15.1798H13.3599C14.2822 15.1798 15.0299 14.4321 15.0299 13.5098V5.15982C15.0299 4.23751 14.2822 3.48982 13.3599 3.48982H12.5249V2.65482C12.5249 2.19367 12.1511 1.81982 11.6899 1.81982C11.2288 1.81982 10.8549 2.19367 10.8549 2.65482V3.48982H5.84492V2.65482C5.84492 2.19367 5.47108 1.81982 5.00992 1.81982ZM5.00992 5.99482C4.54876 5.99482 4.17492 6.36867 4.17492 6.82982C4.17492 7.29098 4.54876 7.66482 5.00992 7.66482H11.6899C12.1511 7.66482 12.5249 7.29098 12.5249 6.82982C12.5249 6.36867 12.1511 5.99482 11.6899 5.99482H5.00992Z"
          fill="#675CE5"
        />
      </svg>
    </div>
  );
};

export default IcoCalendar;
