import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import {
  currencyGETController,
  geolocationFetchController,
} from "../../dataController/dataController";
import { useLocation } from "../../context/locationContext";

const CurrencySelector = ({ handleCurrency }) => {
  const { t } = useTranslation();
  const [currencies, setCurrencies] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState("");
  const { geolocation, initGeoLocation, getQueryParams } = useLocation();

  useEffect(() => {
    // handleCurrency(currentCurrency);
  }, [currentCurrency]);

  useEffect(() => {
    if (geolocation) {
      var url = new URL(
        "https://assist-365.com/" + window.localStorage.getItem("pQuery")
      );
      var search_params = url.searchParams;

      search_params.set("currency_country_id", geolocation.country_id);
      search_params.set("country_code", geolocation.country);
      search_params.set("country_id", geolocation.country_id);
      search_params.set("entity_id", geolocation.entity);
      const urlProductQuery = getQueryParams(search_params);
      localStorage.setItem("pQuery", "?" + urlProductQuery);

      const _resp = {
        country_code: localStorage.getItem("country_code")?.toUpperCase(),
        country_id: parseInt(localStorage.getItem("country_id")),
      };
      handleCurrency(_resp);
      // handleCurrency(getDataCurrency(geolocation.country));
    }
  }, [geolocation]);

  const MenuProps = {
    PaperProps: {
      sx: {
        bgcolor: "white",
        "& .MuiMenuItem-root": {
          paddingTop: 1,
        },
      },
      style: {
        width: 328,
        maxHeight: 400,
        marginTop: "7px",
        borderRadius: "8px",
      },
    },
  };

  useEffect(() => {
    try {
      const fetchData = async () => {
        const result = await currencyGETController.fetchData();
        const resp = result;
        if (resp?.status) {
          setCurrencies(resp?.data);
          setCurrentCurrency(localStorage.getItem("country_code"));
          //handleCurrency(getDataCurrency(localStorage.getItem("country_code")));
        }
      };

      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getDataCurrency = (country_code) => {
    return currencies.find((c) => c.country_code === country_code);
  };

  const handleCurrentCurrency = (value) => {
    try {
      const initGeo = async () => {
        const r = await initGeoLocation(value.target.value, "set");
        setCurrentCurrency(value.target.value);
      };

      initGeo();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {currencies?.length > 0 && (
        <div className="w-full flex xl:w-auto flex-col items-center sm:items-center xl:items-end justify-center currency-selector xl:pt-4 sm:px-[0.9rem] md:px-0">
          <Fade in={true}>
            <Box style={{ width: "320px" }}>
              <h3 className="text-dark-grey uppercase text-xs font-bold tracking-wider font-sans mb-1">
                {t("index.currencyType")}
              </h3>
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <Select
                  style={{
                    fontSize: "16px",
                    fontFamily: "Inter",
                    paddingTop: 0,
                    fontWeight: "400",
                    backgroundColor: "white ",
                    color: "#526166",
                  }}
                  sx={{ width: "100" }}
                  displayEmpty
                  value={currentCurrency}
                  onChange={handleCurrentCurrency}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {currencies.map((d, idx) => (
                    <MenuItem
                      className="text-grey-primary"
                      key={`currency-id-${idx}`}
                      value={d.country_code}
                    >
                      {d.country_name} - ({d.currency})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Fade>
        </div>
      )}
    </>
  );
};

export default CurrencySelector;
