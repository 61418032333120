import { React } from "react";

const icoPin = () => {
  return (
    <div className="w-[17px] h-[17px] flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.05881 3.71848C6.25571 1.52158 9.81759 1.52157 12.0145 3.71848C14.2114 5.91538 14.2114 9.47726 12.0145 11.6742L8.03665 15.652L4.05881 11.6742C1.86191 9.47726 1.86191 5.91538 4.05881 3.71848ZM8.03665 9.30361C8.92434 9.30361 9.64395 8.584 9.64395 7.69632C9.64395 6.80864 8.92434 6.08903 8.03665 6.08903C7.14897 6.08903 6.42936 6.80864 6.42936 7.69632C6.42936 8.584 7.14897 9.30361 8.03665 9.30361Z"
          fill="#675CE5"
        />
      </svg>
    </div>
  );
};

export default icoPin;
