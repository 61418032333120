export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function numberWithDots(number) {
  if (number) {
    try {
      const formatter = new Intl.NumberFormat("es-ES");
      return formatter.format(number);
    } catch (error) {
      console.error(error);
    }
  }
}

export function getInstallments(value) {
  let installments = 1;
  let installment_amount = 0;
  let new_val = value.split(".").join("");

  const COUNTRY_WITH_INSTALLMENTS = ["164"];

  if (COUNTRY_WITH_INSTALLMENTS.includes(localStorage.getItem("country_id"))) {
    if (new_val < 300) {
      installments = 1;
    }
    if (new_val >= 300 && value < 600) {
      installments = 3;
    }
    if (new_val >= 600) {
      installments = 6;
    }

    //! 3 CUOTAS @ MEX
    if (localStorage.getItem("country_id") === "52" && new_val >= 300) {
      installments = 1;
    }

    //! ***** ARGENTINA B2C CUOTAS
    if (localStorage.getItem("country_id") == "164") {
      installments = 3;
    }

    installment_amount = numberWithDots(
      Math.ceil(Number(new_val) / installments)
    );
  } else {
    installments = 1;
    installment_amount = numberWithDots(new_val);
  }

  installment_amount =
    installment_amount.toString().length <= 4
      ? parseInt(installment_amount).toLocaleString("de-DE")
      : installment_amount;

  const resp = {
    installments: installments,
    installment_amount: installment_amount,
  };
  return resp;
}

export function _getInstallments(value,_installments = 1) {
  
  let installments = _installments;
  let installment_amount = 0;

  let new_val = value.split(".").join("");

  const COUNTRY_WITH_INSTALLMENTS = ["164", "165"];

  if (COUNTRY_WITH_INSTALLMENTS.includes(localStorage.getItem("country_id"))) {
    //! 3 CUOTAS @ MEX
    if (localStorage.getItem("country_id") === "52" && new_val >= 300) {
      installments = 1;
    }

    //! ***** ARGENTINA B2C CUOTAS
    if (localStorage.getItem("country_id") == "164") {
      installments = _installments;
    }

    installment_amount = numberWithDots(
      Math.ceil(Number(new_val) / installments)
    );
  } else {
    installments = 1;
    installment_amount = numberWithDots(new_val);
  }

  installment_amount =
    installment_amount.toString().length <= 4
      ? parseInt(installment_amount).toLocaleString("de-DE")
      : installment_amount;

  const resp = {
    installments: installments,
    installment_amount: installment_amount,
  };

  return resp;
}
