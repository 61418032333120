import Modal from "@mui/joy/Modal";
import ModalDialog, { ModalDialogProps } from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogActions from "@mui/joy/DialogActions";
import DialogContent from "@mui/joy/DialogContent";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ModalCoverages = ({
  modalCoverages,
  close,
  handleClose,
  coverages,
  handleSelectProduct,
  gotoComparativeTable,
}) => {
  const { t } = useTranslation();
  const { show, idx, data } = modalCoverages;
  const [benefits, setBenefits] = useState([]);

  useEffect(() => {

    if (idx && coverages?.length > 0) {
      const parsedCoverages = coverages.map((coverage, index) => {
        return {
          name: coverage.name,
          price: coverage[`price${idx}`],
        };
      });
      setBenefits(parsedCoverages);
    }
  }, [idx]);

  return (
    <>
      {benefits?.length > 0 && (
        <Modal open={show} onClose={close}>
          <ModalDialog layout={"center"}>
            <ModalClose onClick={close} />
            <DialogTitle>{data?.name?.toLowerCase()}</DialogTitle>
            <DialogContent>
              <table id="modal__coverages__table" className="benefits__table">
                {benefits.map((benefit, index) => (
                  <tr key={index}>
                    <td>{benefit.name}</td>
                    <td>{benefit.price}</td>
                  </tr>
                ))}
              </table>
              <button
                onClick={gotoComparativeTable}
                type="button"
                className="btn-outlined mb-4"
              >
                {t("index.otherOptions")}
              </button>
            </DialogContent>
            <DialogActions className="coveragesDialogRoot">
              <div className="flex flex-col justify-center modal__dialog__actions min-h-[188px] sm:min-h-[122px] w-full text-white">
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-bold font-titillium capitalize">
                    {data?.name?.toLowerCase()}
                  </span>
                  {data?.discount && (
                    <span className="sm:hidden promo__discount">
                      {data?.discount} OFF
                    </span>
                  )}
                </div>
                <div className="flex-col flex sm:flex-row w-full">
                  <div className="grid grid-cols-[200px_auto] w-full">
                    <div className="data flex flex-col">
                      {data?.installments > 1 && (
                        <span className="font-titillium text-sm font-normal">
                          x{data?.installments} cuotas sin interes de
                        </span>
                      )}
                      <span className="text-3xl font-bold font-titillium">
                        <span>{data?.currency_symbol}</span>{" "}
                        {data?.installment_amount}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <div className="flex flex-col justify-center price text-xs">
                        <span>Precio final</span>
                        <span className="line-through">
                          {data?.currency_symbol} {data?.basic_price}
                        </span>
                        <span className="font-bold">
                          {data?.currency_symbol} {data?.total_price}
                        </span>
                      </div>
                      {data?.discount && (
                        <span className="hidden sm:block promo__discount">
                          {data?.discount} OFF
                        </span>
                      )}
                    </div>
                  </div>
                  <button
                    onClick={() => handleSelectProduct(idx)}
                    className="mt-4 w-full sm:mt-0 sm:w-auto"
                    type="button"
                  >
                    {t("productCard.selectBtn")}
                  </button>
                </div>
              </div>
            </DialogActions>
          </ModalDialog>
        </Modal>
      )}
    </>
  );
};

export default ModalCoverages;
