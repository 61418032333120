import "quoter365/dist/index.css";
// import "../../../root/tracking/sentryConfig";
import "./assets/css/main.css"; // Import global styles
import "./assets/css/tailwind.css"; // Import global styles
import React from "react";
// import Products from "./components/Products";
import Products from "./components/Products/index";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { LanguageProvider } from "./context/LanguageContext";
import { LoaderProvider } from "./context/loaderContext";
import StoreProvider from "./store/StoreProvider";
import { LocationProvider } from "./context/locationContext";
import { BreakpointProvider } from "./context/breakpointContext";

// import * as Sentry from "@sentry/react";

export default function Root(props) {
  return (
    // <Sentry.ErrorBoundary fallback={"Algo salió mal"}>
    <LanguageProvider>
      <I18nextProvider i18n={i18n}>
        <LoaderProvider>
          <LocationProvider>
            <BreakpointProvider>
              <StoreProvider>
                <div className="products-container">
                  <Products />
                </div>
              </StoreProvider>
            </BreakpointProvider>
          </LocationProvider>
        </LoaderProvider>
      </I18nextProvider>
    </LanguageProvider>
    // </Sentry.ErrorBoundary>
  );
}
