import React, { createContext, useContext, useEffect, useState } from "react";
import { geolocationFetchController } from "../dataController/dataController";
import { useTranslation } from "react-i18next";

const LocationContext = createContext();

export const useLocation = () => {
  return useContext(LocationContext);
};

export const LocationProvider = ({ children }) => {
  const url = window.location;
  const isBrLocal = url.hostname === "assist-365.com.br" ? "BR" : null;
  //const isBrLocal = "AR";

  const [geolocation, setGeoLocation] = useState(null);
  const [whatsapp_number, setWhatsapp] = useState(null);
  const { i18n } = useTranslation();
  const currentGEO = location.pathname.split("/")[1];
  const basePath = `/${currentGEO}/checkout`;

  const getGeoUrl = () => {
    const geoUrl = window.location.pathname.split("/")[1];
    return geoUrl;
  };

  const setGeoLocalStorage = (data) => {
    localStorage.setItem("country_code", data.country);
    localStorage.setItem("country_id", data.country_id);
    localStorage.setItem("country_name", data.country_name);
    localStorage.setItem("entity_id", data.entity);
    localStorage.setItem("seller_id", data.seller);
    localStorage.setItem("currency_symbol", data.currency_symbol);
    localStorage.setItem("locale", data.locale);
  };

  /* 
  6: "Australia"
  49: "Costa Rica"
  52: "Mexico"
  135: "Usa"
  137: "Canada"
  157: "Uruguay"
  161: "Peru"
  164: "Argentina"
  165: "Chile"
  199: "España"
  */

  const chatCenter = (country_id) => {
    return [6, 49, 52, 135, 137, 157, 161, 164, 165, 199].includes(
      parseInt(country_id)
    );
  };

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const initWhatsApp = (today_day, today_hour, country_id, header_whatsapp) => {
    try {
      const url =
        "https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/js/chat_support.json";
      const method = "GET";

      fetch(url, {
        method: method,
      })
        .then((response) => response.json())
        .then((response) => {
          const condition = response;
          let whatsapp_number = "";

          let rules = "";
          const total = Object.keys(condition).length;
          let counter = 1;

          Object.keys(condition).forEach((idx) => {
            rules += `(${today_day} === ${idx}`;
            if (condition[idx]) {
              let time = "";
              const operator = condition[idx].match(/[<>]=?|===/gi);

              time =
                condition[idx].length > 0
                  ? condition[idx].slice(
                      operator[0]?.length,
                      condition[idx].length
                    )
                  : null;

              rules += ` && '${today_hour}' ${operator} '${time}')`;
            } else {
              rules += ")";
            }
            if (counter < total) rules += " || ";
            counter++;
          });

          // let _rules = `(${rules})`;
          // if (!_rules) {
          //   _rules = `(${today_day} === "6") || (${today_day} === "7" && "${today_hour}" < "22:01")`;
          // }

          const todayMinutes = timeToMinutes(today_hour);
          const startMinutes = timeToMinutes("21:01");
          const endMinutes = timeToMinutes("02:00");

          const _rules = `
            (${country_id} == 164 && (
            (${todayMinutes} >= ${startMinutes}) || (${todayMinutes} <= ${endMinutes}) || 
            (${today_day} == 6 || ${today_day} == 7)
        )) || ${country_id} != 164`;

          if (eval(_rules)) {
            whatsapp_number = "5491122647575";
          } else {
            if (country_id === 164) {
              whatsapp_number = header_whatsapp.substring(1);
            } else {
              whatsapp_number = chatCenter(country_id)
                ? "5491122647575"
                : header_whatsapp.substring(1);
            }
            if (
              country_id === 155 ||
              country_id === 201 ||
              i18n.language === "pt-BR"
            ) {
              whatsapp_number = "5548988523408";
            }
          }
          setWhatsapp(whatsapp_number);
        });
    } catch (error) {
      throw new Error("Something went wrong");
    }
  };

  const initGeoLocation = async (cc = "", mode = null) => {
    try {
      const country_code = cc ? cc : localStorage.getItem("country_code");
      const result = await geolocationFetchController.fetchData(country_code);
      setGeoLocation(result.data);
      if (mode === "set") {
        setGeoLocalStorage(result?.data);
      }

      const { today_day, today_hour, country_id, header_whatsapp } =
        result.data;

      initWhatsApp(today_day, today_hour, country_id, header_whatsapp);

      return result.data;
    } catch (error) {
      console.warn(error);
    } finally {
    }
  };

  const getQueryParams = (search_params) => {
    let params = "";
    search_params.forEach((value, key) => {
      params += key + "=" + value + "&";
    });

    params = params.slice(0, -1);
    return params;
  };

  const fetchData = async () => {
    if (!geolocation) {
      const r = await initGeoLocation();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const contextValue = {
    whatsapp_number: whatsapp_number,
    geoUrl: getGeoUrl(),
    geolocation,
    basePath,
    url,
    initGeoLocation: initGeoLocation,
    getQueryParams: getQueryParams,
  };

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
};
