import React from "react";
import { capitalizeFirstLetter } from "./../utils/utils";
import Button from "@mui/material/Button";
import { getProductType } from "../config/products.config";
import { useTranslation } from "react-i18next";
import Fade from "@mui/material/Fade";
import IcoShield from "../assets/icon/icoShield";
import IcoId from "../assets/icon/icoId";
import IcoTrash from "../assets/icon/icoTrash";
import { useBreakpoint } from "../context/breakpointContext";

const CardComponent = ({
  product,
  gotoCoverages,
  setModalCoverages,
  selectProduct,
  index,
  multitripLayout,
}) => {
  const { t } = useTranslation();
  const {
    name,
    basic_price,
    total_price,
    discount,
    installments,
    installment_amount,
    type,
    currency_symbol,
    multitrip,
    days,
    promo,
  } = product;
  const benefits = product.benefit;
  const { currentBreakpoint } = useBreakpoint();

  const currencySymbol = capitalizeFirstLetter(currency_symbol);

  const normal =
    !type || (type !== "popular" && type !== "oportunity")
      ? " bg-ribbon-bg text-ribbon-color"
      : "";
  const popular_recommended =
    type === "popular" || type === "recommended"
      ? " bg-ribbon-bg-popular text-ribbon-color-popular"
      : "";
  const oportunity =
    type === "oportunity" || multitrip
      ? " bg-ribbon-bg-oportunity text-ribbon-color-oportunity"
      : "";

  return (
    <>
      <Fade in={true}>
        <div
          className={
            "shadow-md product__container relative md:mx-4" +
            (type ? " mt-[52px] " : " mt-4 md:mt-[52px]") +
            (!type ? " mt-4 md:mt-[52px]" : " mt-4")
          }
        >
          {type &&
            (type === "oportunity" ||
              type === "popular" ||
              type === "recommended") && (
              <div
                className={
                  "product__type " +
                  (type === "oportunity" ? "bg-product-oportunity" : "") +
                  (type === "popular" ? "bg-product-popular" : "") +
                  (type === "recommended" ? "bg-product-recommended" : "")
                }
              >
                {type && type === "oportunity"
                  ? t("productCard.ribbons.oportunity")
                  : type === "recommended"
                  ? t("productCard.ribbons.recommended")
                  : t("productCard.ribbons.popular")}
              </div>
            )}

          <div
            className={
              "product__header " +
              (type ? "bg-product-type" : "bg-product-default")
            }
          >
            {/* {discount && (
              <div className={`ribbon ${normal} ${popular_recommended} ${oportunity}`}>
                {discount} OFF
              </div>
            )} */}
            <span className="product-name font-bold text-4xl capitalize">
              {name.toLowerCase()}
            </span>
            {installments && (
              <span className="installments text-xl mb-2 text-center">
                {installments > 1 && installments}
                {installments > 1 && t("productCard.installments")}
                <span
                  className={
                    "font-bold block capitalize " +
                    (currencySymbol == "VES" ? "text-4xl" : "text-5xl")
                  }
                >
                  {currencySymbol == "ARS" ? (
                    <>
                      <span className="text-lg">ARS</span> ${installment_amount}
                    </>
                  ) : (
                    <>
                      <span className="text-lg">{currencySymbol} </span> {installment_amount}
                    </>
                  )}
                </span>
              </span>
            )}

            <div className="product__wrapper_1">
              {discount && (
                <div className="flex items-center justify-center">
                  <div className="product__discount">{discount} OFF</div>
                </div>
              )}

              <div className="product__price font-sans w-full flex items-center justify-start">
                <div className="flex flex-col items-start">
                  <span className="product-price-base text-xs">
                    {t("productCard.totalPrice")}
                    <span className="line-through block">
                      {currencySymbol} {basic_price}
                    </span>
                  </span>
                  <span className="product-price-final text-sm font-bold">
                    {currencySymbol} {total_price}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {multitrip && (
            <div className="relative left-0 right-0 bottom-0 multitrip-message font-bold text-center font-sans">
              {t("productCard.multitripLimit", { days: days })}
            </div>
          )}
          <div className="product-card-body">
            <div
              className={
                "product-benefits" +
                (multitripLayout && currentBreakpoint !== "xs" && !multitrip
                  ? " pb-16"
                  : "")
              }
            >
              <ul className="space-y-2 text-text-secondary text-sm">
                {benefits.length > 0 &&
                  benefits.map((benefit, index) => {
                    return (
                      <li key={index} className="flex flex-row gap-2">
                        {index === 0 && <IcoShield />}
                        {index === 1 && <IcoId />}
                        {index === 2 && <IcoTrash />}
                        {benefit.price} {benefit.name}
                      </li>
                    );
                  })}
              </ul>
            </div>

            <button
              onClick={() =>
                setModalCoverages({
                  show: true,
                  idx: parseInt(index + 1),
                  data: product,
                })
              }
              className="my-5 text-[#2F6DE0] font-semibold font-titillium text-xl"
            >
              {t("productCard.viewCoverages")}
            </button>
          </div>

          <div className="product-card-footer">
            <Button
              onClick={() => selectProduct(product)}
              fullWidth
              variant="contained"
              size="large"
              style={{
                height: "60px",
                width: "280px",
                textTransform: "capitalize",
                background: "#2F6DE0",
                fontFamily: "Titillium Web",
                fontWeight: 700,
                color: "#fff",
                letterSpacing: 0,
                fontSize: "20px",
                padding: "18px 0",
              }}
            >
              {t("productCard.selectBtn")}
            </Button>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default CardComponent;
