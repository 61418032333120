import React, { createContext, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const BreakpointContext = createContext();

export const useBreakpoint = () => {
  return useContext(BreakpointContext);
};

export const BreakpointProvider = ({ children }) => {

    const [currentBreakpoint, setCurrentBreakpoint] = useState("");

    const isXS = useMediaQuery({ query: "(max-width: 639px)" });
  const isSM = useMediaQuery({
    query: "(min-width: 640px) AND (max-width: 767px",
  });
  const isMD = useMediaQuery({
    query: "(min-width: 768px) AND (max-width: 1023px",
  });
  const isLG = useMediaQuery({
    query: "(min-width: 1024px) AND (max-width: 1280px",
  });
  const isXL = useMediaQuery({
    query: "(min-width: 1280px) AND (max-width: 1535px",
  });
  const is2XL = useMediaQuery({ query: "(min-width: 1536px)" });


  useEffect(() => {
    setCurrentBreakpoint(getCurrentBreakpoint());
  }, [isXS, isSM, isMD, isLG, isXL, is2XL]);

  const getCurrentBreakpoint = () => {
    let breakpoint = "";
    if (isXS) {
      breakpoint = "xs";
    }
    if (isSM) {
      breakpoint = "sm";
    }
    if (isMD) {
      breakpoint = "md";
    }
    if (isLG) {
      breakpoint = "lg";
    }
    if (isXL) {
      breakpoint = "xl";
    }
    if (is2XL) {
      breakpoint = "2xl";
    }
    return breakpoint;
  };

  const contextValue = {
    currentBreakpoint : getCurrentBreakpoint(),
  };

  return (
    <BreakpointContext.Provider value={contextValue}>
      {children}
    </BreakpointContext.Provider>
  );
};
