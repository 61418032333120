import apiService from "../service/apiService";

const QUESTION_ENDPOINT = "track_question";
const PRODUCTS = "products";
const CURRENCY_ENDPOINT = "currency-list";
const GEOLOCATION_ENDPOINT = "geolocation";
const GET_PAYMENT_METHODS = "get-payment-methods";
const CHECKOUT_REGISTER = "checkout-register";
const COVERAGES = "coverages";

const apiRequest = async (
  endpoint,
  method = "GET",
  queryString = "",
  options = {},
  setLoading = () => null
) => {
  try {
    return await apiService.fetchData(
      endpoint,
      method,
      queryString,
      options,
      setLoading
    );
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}`, error);
    throw error;
  }
};

export const questionFetchController = {
  fetchData: async (id) => apiRequest(QUESTION_ENDPOINT, "GET", `id=${id}`),
};

export const coveragesFetchController = {
  fetchData: async (id) => apiRequest(COVERAGES, "GET", `product_id=${id}`),
};

export const currencyGETController = {
  fetchData: async () => {
    return apiRequest(CURRENCY_ENDPOINT, "GET", null);
  },
};

export const productsGETController = {
  fetchData: async (q) => {
    return apiRequest(PRODUCTS, "GET", q);
  },
};

export const geolocationFetchController = {
  fetchData: async (country_code) => {
    const q = country_code ? `flag=true&country_code=${country_code}` : "";
    return apiRequest(GEOLOCATION_ENDPOINT, "GET", q);
  },
};

export const getPaymentMethodsGETController = {
  fetchData: async (id) =>
    apiRequest(GET_PAYMENT_METHODS, "GET", `country_id=${id}`),
};

export const checkoutRegisterController = {
  fetchData: async (body) => apiRequest(CHECKOUT_REGISTER, "POST", null, body),
};
