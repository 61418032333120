import {
  products_order,
  quoter_config,
  countries_promo,
  products_without_discount,
  country_promo_installments,
  getProductType,
} from "./../config/products.config";

const getDiscount = (promo, name) => {
  if (
    promo != "Ninguna" &&
    products_without_discount.indexOf(name.trim().toLowerCase()) === -1
  ) {
    const check_promotion = promo.trim().slice(-2);
    let discount = /\d/.test(check_promotion) ? check_promotion : -1;
    discount =
      discount == -1 || discount == 0 ? null : parseInt(discount) + "%";

    return discount;
  } else {
    return null;
  }
};

const numberWithDots = (number) => {
  if (number) {
    try {
      const formatter = new Intl.NumberFormat("es-ES");
      return formatter.format(number);
    } catch (error) {
      console.error(error);
    }
  }
};

const getInstallmentAmount = (value, installments) => {
  let new_val = value.split(".").join("");

  let installment_amount = numberWithDots(
    Math.ceil(Number(new_val) / installments)
  );
  const amount =
    installment_amount.toString().length <= 4
      ? parseInt(installment_amount).toLocaleString("de-DE")
      : installment_amount;
  return amount;
};

const _getProductType = (name, index) => {
  
  const geo = location.pathname.split("/")[1];
  const product_name = name.trim().toLowerCase();

  if (index === 1) {
    let product_type = "";
    if (
      product_name === "world cover" ||
      (geo === "br" &&
        (product_name === "essential trip" || product_name === "europe care"))
    ) {
      product_type = product_name === "world cover" ? "popular" : "recommended";
    } else {
      product_type = "recommended";
    }

    return product_type;
  } else {
    return getProductType(product_name);
  }
};

const isMultitrip = (name) => {
  const data_multitrip = {
    multitrip: false,
    days: null,
  };

  if (name.toLowerCase().includes("multi")) {
    data_multitrip.multitrip = true;
    name.split(" ").map((word) => {
      if (/\d/.test(word)) {
        data_multitrip.days = parseInt(word);
      }
    });
  }
  return data_multitrip;
};
export const mapProducts = (data) => {
  const { product, benefit, index } = data;
  const name = product.name.trim();
  const discount = getDiscount(product.promo, product.name);
  const installment_amount = getInstallmentAmount(
    product.total_price,
    product.installments
  );
  const type = _getProductType(name, index);
  const multitrip = isMultitrip(name);

  const filtered_benefit = [];
  const benefit_actives = [0, 4, 3];
  
  benefit_actives.map((index) => {
    if (benefit[index]) {
      filtered_benefit.push(benefit[index]);
    }
  });

  filtered_benefit[0]['name'] = "De asistencia médica";

  return {
    name: name,
    basic_price: product.basic_price,
    total_price: product.total_price,
    total_price_back: product.total_price_back,
    total_price_promo: product.total_price_promo,
    total_price_usd: product.total_price_usd,
    discount: discount,
    installments: product.installments,
    installment_amount: installment_amount,
    type: type,
    currency_symbol: product.currency_symbol,
    multitrip: multitrip.multitrip,
    days: multitrip.days,
    benefit: filtered_benefit,
    product_id: product.id,
    promo: product.promo,
  };
};
