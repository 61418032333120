import React, { useState } from "react";
import PhoneSupportDialog from "./phoneSupporDialog";

const PhoneSupport = () => {

  const [open, setOpen] = useState(false);
  return (
    <>
      {open && <PhoneSupportDialog dialogVisibility={setOpen} />}
      <div
        id="btn_telefono_arg"
        onClick={() => setOpen(true)}
        className='bg-[#FFA14F] ml-4 2xl:ml-6 h-[30px] xl:h-[40px] flex items-center justify-center xw-[80px] xl:w-[170px] px-4 rounded-lg text-text-primary text-sm xl:text-xl font-inter font-semibold mr-[36px] cursor-pointer'
      >
        <span className="navbar-hidden md:navbar-block md:block">Teléfono</span>
        <svg
          aria-label='ícono teléfono'
          className='navbar-hidden md:navbar-block md:block navbar-ml-2'
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 25 25'
          fill='none'
        >
          <path
            d='M22.0263 17.5626V20.5626C22.0274 20.8411 21.9704 21.1167 21.8588 21.3719C21.7472 21.6271 21.5836 21.8561 21.3784 22.0444C21.1732 22.2327 20.9309 22.376 20.667 22.4653C20.4032 22.5545 20.1237 22.5876 19.8463 22.5626C16.7691 22.2282 13.8133 21.1767 11.2163 19.4926C8.80013 17.9572 6.75164 15.9087 5.2163 13.4926C3.52628 10.8838 2.47454 7.91355 2.1463 4.82255C2.12131 4.54602 2.15418 4.26731 2.2428 4.00418C2.33143 3.74104 2.47387 3.49924 2.66107 3.29417C2.84826 3.08911 3.07611 2.92526 3.33009 2.81307C3.58408 2.70089 3.85864 2.64281 4.1363 2.64255H7.1363C7.62161 2.63778 8.09209 2.80963 8.46006 3.12609C8.82803 3.44254 9.06838 3.882 9.1363 4.36255C9.26292 5.32262 9.49775 6.26528 9.8363 7.17255C9.97084 7.53048 9.99996 7.91947 9.92021 8.29343C9.84045 8.6674 9.65516 9.01066 9.3863 9.28255L8.1163 10.5526C9.53986 13.0561 11.6128 15.129 14.1163 16.5526L15.3863 15.2826C15.6582 15.0137 16.0015 14.8284 16.3754 14.7486C16.7494 14.6689 17.1384 14.698 17.4963 14.8326C18.4036 15.1711 19.3462 15.4059 20.3063 15.5326C20.7921 15.6011 21.2357 15.8458 21.5528 16.22C21.87 16.5943 22.0385 17.0721 22.0263 17.5626Z'
            fill='#323334'
          />
        </svg>
      </div>
    </>
  );
};

export default PhoneSupport;
