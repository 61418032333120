import { useEffect } from "react";
import Logo from "../../assets/img/logo.png";
import { useBreakpoint } from "../../context/breakpointContext";
import { useLocation } from "../../context/locationContext";
import PhoneSupport from "../PhoneSupport/phoneSupport";
import SideBar from "../PhoneSupport/sideBar";
const Navbar = () => {
  const { geolocation: geo, whatsapp_number: whatsapp } = useLocation();
  const { currentBreakpoint } = useBreakpoint();

  return (
    <div className="w-full bg-blue-secondary relative p-2">
      <div className="w-full">
        <div className="mx-auto flex items-center justify-between lg:max-w-full xl:max-w-[1200px] 2xl:max-w-[1396px] w-ful h-100 px-4 lg:px-0">
          <img src={Logo} className="w-[42px]" />
          {geo?.country_id === 164 &&
            (currentBreakpoint === "lg" ||
              currentBreakpoint === "xl" ||
              currentBreakpoint === "2xl") && <PhoneSupport geo={geo} />}
        </div>
      </div>
      {geo?.country_id === 164 &&
        whatsapp &&
        geo &&
        (currentBreakpoint === "xs" ||
          currentBreakpoint === "sm" ||
          currentBreakpoint === "md") && (
          <SideBar whatsapp={whatsapp} geo={geo} />
        )}
    </div>
  );
};

export default Navbar;
