import { React } from "react";

const IcoTrash = () => {
  return (
    <div className="w-[20px] flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V8C5 7.45 5.19583 6.97917 5.5875 6.5875C5.97917 6.19583 6.45 6 7 6H9V4C9 3.45 9.19583 2.97917 9.5875 2.5875C9.97917 2.19583 10.45 2 11 2H13C13.55 2 14.0208 2.19583 14.4125 2.5875C14.8042 2.97917 15 3.45 15 4V6H17C17.55 6 18.0208 6.19583 18.4125 6.5875C18.8042 6.97917 19 7.45 19 8V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21C17 21.2833 16.9042 21.5208 16.7125 21.7125C16.5208 21.9042 16.2833 22 16 22C15.7167 22 15.4792 21.9042 15.2875 21.7125C15.0958 21.5208 15 21.2833 15 21H9C9 21.2833 8.90417 21.5208 8.7125 21.7125C8.52083 21.9042 8.28333 22 8 22C7.71667 22 7.47917 21.9042 7.2875 21.7125C7.09583 21.5208 7 21.2833 7 21ZM7 19H17V8H7V19ZM10 9C9.71667 9 9.47917 9.09583 9.2875 9.2875C9.09583 9.47917 9 9.71667 9 10V17C9 17.2833 9.09583 17.5208 9.2875 17.7125C9.47917 17.9042 9.71667 18 10 18C10.2833 18 10.5208 17.9042 10.7125 17.7125C10.9042 17.5208 11 17.2833 11 17V10C11 9.71667 10.9042 9.47917 10.7125 9.2875C10.5208 9.09583 10.2833 9 10 9ZM14 9C13.7167 9 13.4792 9.09583 13.2875 9.2875C13.0958 9.47917 13 9.71667 13 10V17C13 17.2833 13.0958 17.5208 13.2875 17.7125C13.4792 17.9042 13.7167 18 14 18C14.2833 18 14.5208 17.9042 14.7125 17.7125C14.9042 17.5208 15 17.2833 15 17V10C15 9.71667 14.9042 9.47917 14.7125 9.2875C14.5208 9.09583 14.2833 9 14 9ZM11 6H13V4H11V6Z"
          fill="#00C7A6"
        />
      </svg>
    </div>
  );
};

export default IcoTrash;
