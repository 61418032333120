import { React } from "react";

const IcoId = () => {
  return (
    <div className="w-[20px] flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M7 16V17C7 17.2833 7.09583 17.5208 7.2875 17.7125C7.47917 17.9042 7.71667 18 8 18C8.28333 18 8.52083 17.9042 8.7125 17.7125C8.90417 17.5208 9 17.2833 9 17V16H10C10.2833 16 10.5208 15.9042 10.7125 15.7125C10.9042 15.5208 11 15.2833 11 15C11 14.7167 10.9042 14.4792 10.7125 14.2875C10.5208 14.0958 10.2833 14 10 14H9V13C9 12.7167 8.90417 12.4792 8.7125 12.2875C8.52083 12.0958 8.28333 12 8 12C7.71667 12 7.47917 12.0958 7.2875 12.2875C7.09583 12.4792 7 12.7167 7 13V14H6C5.71667 14 5.47917 14.0958 5.2875 14.2875C5.09583 14.4792 5 14.7167 5 15C5 15.2833 5.09583 15.5208 5.2875 15.7125C5.47917 15.9042 5.71667 16 6 16H7ZM13.75 14.5H18.25C18.4667 14.5 18.6458 14.4292 18.7875 14.2875C18.9292 14.1458 19 13.9667 19 13.75C19 13.5333 18.9292 13.3542 18.7875 13.2125C18.6458 13.0708 18.4667 13 18.25 13H13.75C13.5333 13 13.3542 13.0708 13.2125 13.2125C13.0708 13.3542 13 13.5333 13 13.75C13 13.9667 13.0708 14.1458 13.2125 14.2875C13.3542 14.4292 13.5333 14.5 13.75 14.5ZM13.75 17.5H16.25C16.4667 17.5 16.6458 17.4292 16.7875 17.2875C16.9292 17.1458 17 16.9667 17 16.75C17 16.5333 16.9292 16.3542 16.7875 16.2125C16.6458 16.0708 16.4667 16 16.25 16H13.75C13.5333 16 13.3542 16.0708 13.2125 16.2125C13.0708 16.3542 13 16.5333 13 16.75C13 16.9667 13.0708 17.1458 13.2125 17.2875C13.3542 17.4292 13.5333 17.5 13.75 17.5ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V9C2 8.45 2.19583 7.97917 2.5875 7.5875C2.97917 7.19583 3.45 7 4 7H9V4C9 3.45 9.19583 2.97917 9.5875 2.5875C9.97917 2.19583 10.45 2 11 2H13C13.55 2 14.0208 2.19583 14.4125 2.5875C14.8042 2.97917 15 3.45 15 4V7H20C20.55 7 21.0208 7.19583 21.4125 7.5875C21.8042 7.97917 22 8.45 22 9V20C22 20.55 21.8042 21.0208 21.4125 21.4125C21.0208 21.8042 20.55 22 20 22H4ZM4 20H20V9H15C15 9.55 14.8042 10.0208 14.4125 10.4125C14.0208 10.8042 13.55 11 13 11H11C10.45 11 9.97917 10.8042 9.5875 10.4125C9.19583 10.0208 9 9.55 9 9H4V20ZM11 9H13V4H11V9Z"
          fill="#00C7A6"
        />
      </svg>
    </div>
  );
};

export default IcoId;
