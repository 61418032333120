//? Products Order

export const products_order = [
  "world cover",
  "safe travel",
  "premium cover",
  "multitrip 30",
  "multitrip 60",
  "multitrip plus 30",
  "multitrip plus 60",
  "global backpacker",
  "safe exchange",
  "world explorer",
  "multitrip 90",
  "multitrip plus 90",
  "wander travel",
  "essential travel",
  "europe care"
];

//? Quoter Config

export const quoter_config = {
  local_storage_data: true,
  // redirection_url: 'http://localhost:4200/ar/productos',
  redirection: false,
  geolocation: false,
  type: "default",
  language: "es",
  market: "global",
  min_days_quotation: 2,
  max_years_quotation: 3,
  max_days_quotation: 364,
  pax_limit: 9,
  age_groups: {
    age_1: 38,
    age_2: 75,
  },
  days_limit: 120,
  mode: "normal",
  dates: {
    weekdays: false,
  },
  view_mode: "normal",
};

//? Countries Promo

export const countries_promo = [
  { id: 164, code: "ar", name: "Argentina" },
  { id: 52, code: "mx", name: "Mexico" },
  { id: 155, code: "br", name: "Brasil" },
];

//? Products Promo Installments

export function country_promo_installments() {
  //? Products with 3 installments
  const countries = [
    155, // Brasil
    52, // Mexico
  ];

  const country_id = localStorage.getItem("country_id");
  return countries.includes(country_id);
}

//? Products without discount

export const products_without_discount = ["nomad health", "remote care"];

//? Check product type

export const getProductType = (product) => {
  // {
  //   type: "popular",
  //   products: ["safe travel", "safe exchange"],
  // },
  const types = [
    {
      type: "oportunity",
      products: ["multitrip 30", "multitrip 60", "multitrip 90"],
    },
    {
      type: "multitrip",
      products: [
        "multitrip 30",
        "multitrip plus 30",
        "multitrip 60",
        "multitrip plus 60",
        "multitrip 90",
        "multitrip plus 90",
      ],
    },
    {
      type: "franchise",
      products: [
        "hexagon",
        "hexagon (long trip)",
        "heptagon",
        "heptagon (long trip)",
        "triangle",
        "triangle (long trip)",
      ],
    },
  ];

  const resp = types.find((elem) =>
    elem.products.includes(product.trim().toLowerCase())
  );
  return (resp && resp.type) ?? false;
};
