import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ComparativeTableHeader = ({
  navigator,
  comparativeTable,
  products_length,
  dataTable,
  breakpoint,
  productSelected,
}) => {
  const [stickyClass, setStickyClass] = useState("");
  const { names, types, total_price, installments } = comparativeTable;
  const { t } = useTranslation();

  const handleSelectProduct = (index = 0) => {
    if (comparativeTable["price"][index]) {
      productSelected(comparativeTable["price"][index]);
    }
  };

  const getClassNames = (type) => {
    let _classes = `w-full text-2xl font-semibold font-sans px-0 capitalize 
    ${type ? "bg-product-type" : "bg-product-default"}
    `;
    return _classes;
  };

  const _navigator = (mode) => {
    navigator(mode);
    setStickyClass("sticky-nav");
  };

  return (
    <>
      <div
        className={
          `navbar sticky-nav ${stickyClass}` +
          (breakpoint == "sm" ? " h-[142px] " : "") +
          (breakpoint != "xs" ? " mb-[28px] " : "") +
          (breakpoint == "xl" || breakpoint == "2xl" ? " top-0 pt-[20px] " : "")
        }
      >
        {/* mobile */}

        {breakpoint == "xs" && (
          <div className="flex items-center justify-center">
            <button
              onClick={() => _navigator("prev")}
              type="button"
              className="ml-auto btn-comparative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="31"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M15.5195 6L9.51953 12L15.5195 18"
                  stroke="#2F6DE0"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <div
              className={
                "ct-header xs flex items-center justify-center mx-auto max-w-[260px] " +
                getClassNames(types[dataTable["ids"][0] - 1])
              }
            >
              <div className="w-full table__header xs">
                <span className="table__name">
                  {names[dataTable["ids"][0] - 1]}
                </span>
                {installments[0]?.total > 0 && (
                  <span className="table__installments text-sm block w-full text-center">
                    {installments[0]?.total} {t("productCard.installments")}
                  </span>
                )}
                <span className="table__total_price text-3xl">
                  <span class="table__currency">
                    {comparativeTable?.currency}
                  </span>
                  {installments[0]?.total > 0
                    ? installments[dataTable["ids"][0] - 1]?.amount
                    : total_price[dataTable["ids"][0] - 1]}
                </span>
                <button type="button" onClick={() => handleSelectProduct(0)}>
                  {t("productCard.selectBtn")}
                </button>
              </div>
            </div>
            <button
              onClick={() => _navigator("next")}
              type="button"
              className="btn-comparative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="31"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M9.51953 18L15.5195 12L9.51953 6"
                  stroke="#2F6DE0"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        )}

        {breakpoint != "xs" && (
          <div className="w-full">
            <div
              id="tc-table"
              className="tc-table desktop flex items-center justify-center"
            >
              <table className="font-sans text-xs font-normal leading-3 text-text-tertiary">
                <tbody>
                  <tr>
                    <td>
                      {dataTable.limit < products_length && (
                        <div className="flex items-center justify-center">
                          <button
                            onClick={() => _navigator("prev")}
                            type="button"
                            className="btn-comparative"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="31"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M15.5195 6L9.51953 12L15.5195 18"
                                stroke="#2F6DE0"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <button
                            onClick={() => _navigator("next")}
                            type="button"
                            className="ml-2 btn-comparative"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="31"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.51953 18L15.5195 12L9.51953 6"
                                stroke="#2F6DE0"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </td>
                    {Array.from({ length: dataTable.limit }, (_, idx) => {
                      return (
                        <td
                          key={idx}
                          className={getClassNames(
                            types[dataTable["ids"][idx] - 1]
                          )}
                        >
                          <div className="w-full table__header relative desktop">
                            <div className="self-start">
                              <span className="table__name">
                                {names[dataTable["ids"][idx] - 1]}
                              </span>
                              {installments[0]?.total > 0 && (
                                <span className="table__installments text-xs">
                                  {installments[idx]?.total}{" "}
                                  {t("productCard.installments")}
                                </span>
                              )}
                              <span className="table__total_price">
                                <span class="table__currency">
                                  {comparativeTable?.currency}
                                </span>
                                {installments[0]?.total > 0
                                  ? installments[dataTable["ids"][idx] - 1]
                                      ?.amount
                                  : total_price[dataTable["ids"][idx] - 1]}
                              </span>
                            </div>
                            <div className="self-end w-full">
                              <button
                                type="button"
                                onClick={() => handleSelectProduct(idx)}
                              >
                                {t("productCard.selectBtn")}
                              </button>
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ComparativeTableHeader;
